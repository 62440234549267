import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title={"Dryline Partners"} description={"Dryline Partners"} />

    <nav id="navbar">
      <div
        className={`border-b flex  justify-center items-center  flex-wrap lg:py-6 mx-6 lg:mx-10`}
      >
        <div className=" flex text-white py-6 lg:p-0 relative z-20 ">
          <Link className={"block"} to="/">
            <StaticImage
              src={"../../images/logo.png"}
              width={200}
              formats={["auto", "webp", "avif"]}
              alt="Dryline Partners"
            />
          </Link>
        </div>
      </div>
    </nav>

    <div className="footer-form text-white bg-black py-20 lg:p-20 overflow-hidden">
      <div className="container mx-auto lg:px-20  relative">
        <div className="max-w-3xl overflow-hidden mx-10 lg:mx-20">
          <h3
            data-sal="fade"
            data-sal-duration="1000"
            className="text-4xl lg:text-6xl font-semibold text-white py-20"
          >
            Let us know more about you and how we can connect.
          </h3>
          <form
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-delay="200"
            className="font-serif"
            action="/success"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">My name is, </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-10 mb-1 bg-transparent "
                required
                type="text"
                name="name"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">I'm a/an </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                type="detail"
                name="detail"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">Please contact me about </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                type="message"
                name="message"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">Email me at </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                required
                type="email"
                name="email"
              />
            </div>
            <div className="w-full text-right py-5">
              <button className="text-purple text-xl" type="submit">
                → SEND
              </button>
            </div>
          </form>
        </div>
        <div className="-right-32 bottom-20 absolute">
          <StaticImage
            src="../images/contact.png"
            width={400}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="tio"
          />
        </div>
      </div>
    </div>

    <div className="flex relative flex-wrap w-full py-20 lg:pt-12 lg:pb-36 bg-black text-lg text-white font-serif justify-center">
      <div className="block  lg:w-1/2 px-10 text-center lg:py-20 lg:px-44 ">
        <StaticImage
          src="../images/logo-white.png"
          width={200}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="tio"
        />
      </div>
    </div>

    <div className="block bg-black ">
      <div className="mx-10 lg:mx-20 py-5 justify-between  items-center border-t border-peach  flex-col lg:flex-row block">
        <div className="text-white font-serif pt-10 lg:pt-0">
          © Dry Line Partners. All rights reserved.
        </div>
      </div>
    </div>
  </>
)

export default IndexPage
